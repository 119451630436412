// src/views/CoachProfile.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';
import PicktimeBooking from '../components/PicktimeBooking';
import { motion } from 'framer-motion';
import coaches from '../data/coaches.js'; // Import coaches data
import './CoachProfile.css';

const CoachProfile = () => {
    const { coachId } = useParams();
    const coach = coaches.find(c => c.id === coachId);

    const [topMargin, setTopMargin] = useState('100px');

    useEffect(() => {
        const updateTopMargin = () => {
            const headerHeight = document.querySelector('.header-container').offsetHeight;
            setTopMargin(`${headerHeight}px`);
        };

        updateTopMargin();
        window.addEventListener('resize', updateTopMargin);

        return () => {
            window.removeEventListener('resize', updateTopMargin);
        };
    }, []);

    if (!coach) {
        return <Typography variant="h4">Coach not found</Typography>;
    }

    return (
        <div className='coach-profile-background'>
            <Header />
            <Box className="coach-profile-container" sx={{ mt: topMargin, p: 2 }}>
                <div className="coach-profile-container">
                    <div className="coach-image-container">
                        <img src={coach.photo} alt={coach.name} className="coach-photo" />
                    </div>
                    <div className="coach-info">
                        <Typography variant="h2">{coach.name}</Typography>
                        <br /><br />
                        <Typography variant="body1">
                            {coach.bio.split('\n').map((line, index) => (
                                <span key={index}>
                                    {line}
                                    <br /><br />
                                </span>
                            ))}
                        </Typography>
                        <div className="coaching-rates">
                            <Typography variant="h3">Coaching rates</Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Adult</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Rate</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {coach.rates.adult.map((rate, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{rate.duration}</TableCell>
                                                <TableCell>{rate.rate}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Junior</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Rate</TableCell>
                                        </TableRow>
                                        {coach.rates.junior.map((rate, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{rate.duration}</TableCell>
                                                <TableCell>{rate.rate}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div className="centered-button-container">
                            <Link to="https://www.picktime.com/YMGC" style={{ textDecoration: 'none' }}>
                                <motion.button 
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.98 }}
                                    transition={{ type: 'spring', stiffness: 400, damping: 15 }}
                                    className="booking-button-gradient booking-button-pulse" 
                                >
                                    BOOK A LESSON
                                </motion.button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Box>
            <Footer />
        </div>
    );
};

export default CoachProfile;