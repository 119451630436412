// src/data/coaches.js
// Using CommonJS module syntax instead of ES modules
const coaches = [
  {
    id: 'cameron-bell',
    name: 'Cameron Bell',
    title: 'PGA Professional',
    photo: '/images/coaches/Cameron.jpg', // Update with actual photo path
    bio: `Cameron Bell is a dedicated PGA Professional with a passion for helping golfers of all skill levels improve their game. With a strong playing background, including competing in the Adidas Pro-Am Series and Australasian Tour events, Cam brings valuable insight into what it takes to perform at a high level.

His coaching philosophy focuses on simplicity, ensuring golfers develop a strong foundation in grip, balance, and alignment to make your swing more natural and efficient. Whether you're a beginner looking to build confidence or an experienced player refining your skills, Cam provides clear, practical instruction tailored to your needs.

Before joining Yering Meadows, Cam honed his skills at Woodlands Golf Club and Heidelberg Golf Club while completing his PGA Associate Program. His experience in club fitting, course management, and competitive play gives him a well-rounded approach to coaching.

Book a lesson with Cam today to take your game to the next level!

Contact: cam@yeringmeadows.com.au | 0449 929 339`,
    rates: {
      adult: [
        { duration: 'Individual 30 Minute Lessons', rate: 'AU$ 80.00' },
        { duration: 'Individual 60 Minute Lessons', rate: 'AU$ 135.00' },
        { duration: 'Individual On Course Lesson (2 hrs)', rate: 'AU$ 280.00' }
      ],
      junior: [
        { duration: '10-16 Junior 30 Minute Lessons', rate: 'AU$ 50.00' },
        { duration: '10-16 year old Junior 60 Minute Lessons', rate: 'AU$ 90.00' },
        { duration: 'Junior On Course Lesson (2 hrs)', rate: 'AU$ 200.00' }
      ]
    }
  }
];

// CommonJS export
module.exports = coaches;