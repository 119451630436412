import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import './ReciprocalClubs.css';

const ReciprocalClubs = () => {
    const [expandedRegions, setExpandedRegions] = useState({});

    const toggleRegion = (region) => {
        setExpandedRegions(prev => ({
        ...prev,
        [region]: !prev[region]
        }));
    };

    const regions = {
        'Red Reciprocal Card': [
            {
                name: 'Anglesea Golf Club',
                description: 'A stunning 18-hole golf course located along the Great Ocean Road, known for its breathtaking scenery and resident kangaroo population.',
                address: '1 Golf Links Road, Anglesea, VIC 3230, Australia',
                phone: '+61 3 5263 1951',
                website: 'www.angleseagolfclub.com.au'
            },
            {
                name: 'Box Hill Golf Club',
                description: 'A premier golf destination in Melbourne\'s eastern suburbs with an 18-hole, par-71 course.',
                address: '202 Station Street, Box Hill South, VIC 3128, Australia',
                phone: '+61 3 9808 1526',
                website: 'www.boxhillgolfclub.com.au'
            },
            {
                name: 'Devil Bend Golf Club',
                description: 'A tranquil 18-hole course set within the scenic Mornington Peninsula with lush fairways and strategic bunkering.',
                address: '48 Loders Road, Moorooduc, VIC 3933, Australia',
                phone: '+61 3 5978 8470',
                website: 'www.devilbendgolf.com.au'
            },
            {
                name: 'Gardiners Run Golf Club',
                description: 'A modern 18-hole course in the Yarra Valley with undulating fairways and picturesque water features.',
                address: '132 Victoria Road, Lilydale, VIC 3140, Australia',
                phone: '+61 3 9739 7522',
                website: 'www.gardinersrun.com.au'
            },
            {
                name: 'Medway Golf Club',
                description: 'A historic 18-hole course in Melbourne\'s west, established in 1930.',
                address: 'Omar Street, Maidstone, VIC 3012, Australia',
                phone: '+61 3 9317 9031',
                website: 'www.medwaygolf.com.au'
            },
            {
                name: 'Churchill-Waverley Golf & Bowls Club (Rowville)',
                description: 'An 18-hole course featuring well-maintained fairways and greens.',
                address: '82 Bergins Road, Rowville, VIC 3178, Australia',
                phone: '(03) 9764 4559',
                website: 'www.waverleygolfclub.com.au'
            },
            {
                name: 'Churchill-Waverley Golf & Bowls Club (Endeavour Hills)',
                description: 'A course known for its picturesque setting amidst natural bushland.',
                address: '113 Churchill Park Drive, Endeavour Hills, VIC 3802, Australia',
                phone: '(03) 9700 4445',
                website: 'www.waverleygolfclub.com.au'
            },
            {
                name: 'Mornington Golf Club',
                description: 'An 18-hole course with breathtaking views across Port Phillip Bay.',
                address: 'Tallis Drive, Mornington, VIC 3931, Australia',
                phone: '(03) 5975 2784',
                website: 'www.morningtongolf.com.au'
            },
            {
                name: 'Rossdale Golf Club',
                description: 'An 18-hole course renowned for its friendly atmosphere and strategic bunkering.',
                address: 'Corner of First and Sixth Avenue, Aspendale, VIC 3195, Australia',
                phone: '(03) 9580 1008',
                website: 'www.rossdalegolf.com.au'
            }
        ],
        '/Victoria': [
            {
                name: 'Cohuna Golf Club',
                description: 'A scenic 18-hole course set among stunning red gum forests in Victoria\'s Loddon Murray Region.',
                address: 'Weymouths Road, Cohuna VIC 3568, Australia',
                phone: '(03) 5456 2820',
                website: 'www.cohunagolfclub.au'
            },
            {
                name: 'Shepparton Golf Club',
                description: 'An 18-hole course nestled along the majestic banks of the Goulburn River, built on natural sandhills.',
                address: '15 Golf Drive, Shepparton, VIC 3630, Australia',
                phone: '+61 3 5821 2717',
                website: 'www.sheppartongolf.net.au'
            }
        ],
        '/New South Wales': [
            {
                name: 'Coolangatta & Tweed Heads Golf Club',
                description: 'Two championship 18-hole courses set amidst lush, verdant landscapes.',
                address: 'Soorley Street, Tweed Heads South NSW 2486, Australia',
                phone: '+61 7 5524 4544',
                website: 'www.cooltweedgolf.com.au'
            },
            {
                name: 'Lithgow Golf Club',
                description: 'An 18-hole course surrounded by natural bushland and rolling hills.',
                address: 'Marrangaroo Rd, Marrangaroo NSW 2790, Australia',
                phone: '+61 2 6351 3164',
                website: 'www.lithgowgolfclub.com.au'
            },
            {
                name: 'Rich River Golf Club',
                description: 'Two distinct 18-hole championship courses set among beautiful landscapes.',
                address: 'Twenty Four Lane, Moama NSW 2731, Australia',
                phone: '+61 3 5481 3333',
                website: 'www.richriver.com.au'
            },
            {
                name: 'Roseville Golf Club',
                description: 'A challenging 18-hole course set within a tranquil bushland setting.',
                address: '4 Links Ave, Roseville NSW 2069, Australia',
                phone: '+61 2 8467 1800',
                website: 'www.rosevillegolf.com.au'
            },
            {
                name: 'Tocumwal Golf Club',
                description: 'A 36-hole championship facility located on the Murray River.',
                address: 'Barooga Rd, Tocumwal NSW 2714, Australia',
                phone: '+61 3 5874 9111',
                website: 'www.clubtocumwal.com'
            },
            {
                name: 'Tura Beach Country Club',
                description: 'An 18-hole championship course designed by Peter Thomson and Mike Wolveridge.',
                address: '5 The Fairway, Tura Beach NSW 2548, Australia',
                phone: '+61 2 6495 9002',
                website: 'www.turabeachcountryclub.com.au'
            },
            {
                name: 'Yarrawonga Mulwala Golf Club Resort',
                description: 'Australia\'s largest public access golf resort with three courses totaling 45 holes.',
                address: 'Golf Club Rd, Mulwala NSW 2647, Australia',
                phone: '+61 3 5744 1911',
                website: 'www.yarragolf.com.au'
            }
        ],
        '/Queensland': [
            {
                name: 'Nudgee Golf Club',
                description: 'Two 18-hole championship courses with excellent facilities.',
                address: '1207 Nudgee Road, Nudgee, QLD 4014, Australia',
                phone: '+61 7 3267 7744',
                website: 'www.nudgeegolf.com.au'
            },
            {
                name: 'Virginia Golf Club',
                description: 'A 27-hole facility known as "The Course of Champions."',
                address: '2 Elliott Road, Banyo, QLD 4014, Australia',
                phone: '+61 7 3267 6333',
                website: 'www.virginiagolf.com.au'
            },
            {
                name: 'Lakelands Golf Club',
                description: 'An 18-hole championship course designed by Jack Nicklaus.',
                address: '100 Lakelands Drive, Merrimac, QLD 4226, Australia',
                phone: '+61 7 5510 6500',
                website: 'www.lakelandsgolfclub.com.au'
            },
            {
                name: 'Noosa Golf Club',
                description: 'An 18-hole, par 72 championship course set amidst natural bushland.',
                address: '46 Cooroy-Noosa Road, Tewantin QLD 4565, Australia',
                phone: '(07) 5447 1407',
                website: 'www.noosagolf.com.au'
            }
        ],
        '/South Australia': [
            {
                name: 'The Vines Golf Club of Reynella',
                description: 'A challenging par 71 course at the gateway to Adelaide\'s southern wine region.',
                address: 'Cnr Reynell & Pine Roads, Happy Valley SA 5159, Australia',
                phone: '+61 8 8381 1822',
                website: 'www.vinesgolfclub.com.au'
            },
            {
                name: 'The Stirling Golf Club',
                description: 'An 18-hole course surrounded by lush greenery in the Adelaide Hills.',
                address: '35 Golflinks Road, Stirling SA 5152, Australia',
                phone: '+61 8 8339 1805',
                website: 'www.stirlinggolf.com.au'
            },
            {
                name: 'Port Lincoln Golf Club',
                description: 'An 18-hole course with well-maintained fairways and greens on the Eyre Peninsula.',
                address: '495 Flinders Highway, Port Lincoln SA 5607, Australia',
                phone: '+61 8 8682 1352',
                website: 'www.plgc.com.au'
            },
            {
                name: 'Blackwood Golf Club',
                description: 'One of the most picturesque courses in the Adelaide Hills.',
                address: '611 Cherry Gardens Road, Cherry Gardens SA 5157, Australia',
                phone: '+61 8 8388 2313',
                website: 'www.blackwoodgolf.com.au'
            }
        ],
        '/Tasmania': [
            {
                name: 'Ulverstone Golf Club',
                description: 'An 18-hole, par 72 championship course spanning 6,160 meters within a tall eucalypt forest.',
                address: 'Lobster Creek Road, Ulverstone TAS 7315, Australia',
                phone: '+61 3 6425 2322',
                website: 'ulverstonegolfclub.com.au'
            }
        ],
        '/Western Australia': [
            {
                name: 'Melville Golf Centre',
                description: 'A comprehensive golfing facility with a floodlit driving range and nine-hole par-three course.',
                address: 'Melville Golf Centre, Leeming, WA, Australia',
                phone: 'Not available',
                website: 'www.melvillegolfcentre.com'
            },
            {
                name: 'Royal Fremantle Golf Club',
                description: 'An 18-hole championship course established in 1905.',
                address: '359 High Street, Fremantle, WA 6160, Australia',
                phone: '+61 8 9335 3866',
                website: 'www.royalfremantlegc.com.au'
            },
            {
                name: 'Sea View Golf Club',
                description: 'A 9-hole links-style course with stunning views of the Indian Ocean.',
                address: 'Jarrad Street, Cottesloe, WA 6011, Australia',
                phone: '(08) 9384 8879',
                website: 'www.seaviewgolfclub.com.au'
            }
        ],
        '/New Zealand': [
            {
                name: 'Boulcott\'s Farm Heritage Golf Club',
                description: 'An 18-hole, par 71 course spanning 5,933 meters with a 6-hole Summerset Course.',
                address: '33 Military Road, Lower Hutt, Wellington, New Zealand',
                phone: '+64 (4) 567 4722',
                website: 'www.boulcottsfarmhgc.co.nz'
            },
            {
                name: 'Clearwater Golf Club',
                description: 'An 18-hole, par-72 championship course designed by John Darby and Sir Bob Charles.',
                address: '40a Clearwater Avenue, Christchurch, New Zealand',
                phone: '+64 3-360 2146',
                website: 'www.clearwatergolf.co.nz'
            }
        ],
        '/Germany': [
            {
                name: 'Golf Club Würzburg e.V',
                description: 'One of the most beautiful golf courses in southern Germany with 18 holes.',
                address: 'Am Golfplatz 2, 97084 Würzburg, Germany',
                phone: '+49 931 67890',
                website: 'www.golfclub-wuerzburg.de'
            }
        ],
        '/Scotland': [
            {
                name: 'Mortonhall Golf Club',
                description: 'Edinburgh\'s oldest golf course still in play, established in 1892.',
                address: '231 Braid Road, Edinburgh, EH10 6PB, Scotland',
                phone: '+44 131 447 6974',
                website: 'www.mortonhallgc.co.uk'
            }
        ],
        '/China': [
            {
                name: 'Mission Hills China - Shenzhen',
                description: 'The world\'s largest golf resort featuring 12 championship courses.',
                address: 'No. 1 Mission Hills Road, Guanlan, Shenzhen, Guangdong, China',
                phone: '+86 755 2802 8888',
                website: 'www.missionhillschina.com'
            },
            {
                name: 'Mission Hills China - Haikou',
                description: 'A tropical golfing experience with multiple championship courses.',
                address: '1 Mission Hills Road, Haikou, Hainan, China',
                phone: '+86 898 6688 8888',
                website: 'www.missionhillschina.com'
            },
            {
                name: 'Jiulongshan Zhejiang Golf Club',
                description: 'A 27-hole, par-108 links-style golf course blending forest, lake, and coastal landscapes.',
                address: 'Jiulongshan Tourism Resort, Pinghu City, Zhejiang Province, China',
                phone: '+86 573 8559 0000',
                website: 'www.963golfclub.com'
            },
            {
                name: 'Dongfang Xiamen Golf & Country Club',
                description: 'A 27-hole international standard championship golf course.',
                address: '588 Ma Qing Road, Haicang Investment and Development Zone, Xiamen, Fujian Province, China',
                phone: '+86 592 653 1950',
                website: 'www.orientgolf.com'
            },
            {
                name: 'Guanshanhai Golf Club',
                description: 'An 18-hole international standard golf course combining coastal and mountain landscapes.',
                address: '86 Guanling Road, Haicheng District, Beihai City, Guangxi, China',
                phone: '+86 779 303 8888',
                website: 'www.haimengolf.com'
            },
            {
                name: 'Nanjing Longshan Lake Golf Club',
                description: 'An 18-hole international standard mountain and lake-view golf course spanning approximately 2,500 acres.',
                address: 'Xingxiang Town, Jurong City, Nanjing, Jiangsu Province, China',
                phone: '+86 511 8728 8888',
                website: 'www.lakeshillgolf.com'
            },
            {
                name: 'Chongqing Hongding Golf Club',
                description: 'A 7,008-yard par 72 course designed by Nelson Haworth.',
                address: 'Tongjiaxi (Sanxikou), Beibei District, Chongqing, China',
                phone: 'Not available',
                website: 'Not available'
            },
            {
                name: 'Nantong Yangtze Golf Club',
                description: 'An 18-hole international standard championship golf course on Kaisha Island.',
                address: 'Kaisha Island, Wujie Town, Tongzhou District, Nantong, Jiangsu Province, China',
                phone: '+86 513 8688 8888',
                website: 'www.ntkjgolf.com'
            },
            {
                name: 'Nanjing Yangtze Golf Club',
                description: 'A 27-hole parkland-style course designed by Gary Player.',
                address: 'Huanling Road, Xuanwu District, Nanjing, Jiangsu Province, China',
                phone: '+86 25 8460 6666',
                website: 'www.zhongshangolf.com'
            },
            {
                name: 'Wanbang Longtao Golf Club',
                description: 'An 18-hole international standard mountain course designed by Nelson & Haworth.',
                address: 'No. 1 Longtao Road, Huangshan Peak, Jiangyin City, Jiangsu Province, China',
                phone: '+86 510 8688 8888',
                website: 'www.longtaogolf.com'
            },
            {
                name: 'Huai\'an Shixing Golf Club',
                description: 'A hilly style course featuring hills, slopes, and ponds.',
                address: 'Fanfan, Nanmachang Township, Huai\'an Economic and Technological Development Zone, Jiangsu Province, China',
                phone: '+86 517 8379 3029',
                website: 'Not available'
            },
            {
                name: 'Lushan International Golf Club',
                description: 'A 27-hole international standard hilly layout near Lushan Mountain.',
                address: 'Golf Avenue, Gongqing City, Jiujiang, Jiangxi Province, China',
                phone: '+86 792 434 6888',
                website: 'www.lushangolf.com.cn'
            },
            {
                name: 'Quanzhou Zhongyuan Golf Club',
                description: 'An 18-hole international standard hilly layout spanning 7,108 yards.',
                address: 'Zimao Town, Jinjiang City, Quanzhou, Fujian Province, China',
                phone: '+86 595 8595 1988',
                website: 'www.qzgolf.com'
            }
        ],
        '/USA': [
            {
                name: 'The Golf Club of Amelia Island',
                description: 'An 18-hole championship golf course designed by Mark McCumber and Gene Littler.',
                address: '4700 Amelia Island Parkway, Fernandina Beach, FL 32034',
                phone: '904-277-0021',
                website: 'www.golfclubofamelia.com'
            }
        ]
    };

  return (
    <div className="page-container">
      <Header />
      <div className="main-content">
        <h1>Reciprocal Clubs</h1>
        
        <div className="regions-wrapper">
          {Object.entries(regions).map(([region, clubs]) => (
            <div key={region} className="region-section">
              <button
                className={`region-button ${expandedRegions[region] ? 'expanded' : ''}`}
                onClick={() => toggleRegion(region)}
              >
                {region}
                <ChevronDown className="chevron-icon" />
              </button>
              
              {expandedRegions[region] && (
                <div className="clubs-list">
                  {clubs.map((club, index) => (
                    <div key={index} className="club-item">
                      <h3>{club.name}</h3>
                      <p className="description">{club.description}</p>
                      <div className="details">
                        <p>{club.address}</p>
                        <p>{club.phone}</p>
                        <p>{club.website}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="contact-section">
          <p>For any enquiries regarding reciprocal clubs, please contact:</p>
          <a href="mailto:jimbo@yeringmeadows.com.au">jimbo@yeringmeadows.com.au</a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReciprocalClubs;