import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import './Membership.css';
import { Link } from 'react-router-dom';
import sampleimg from '../assets/images/Golf/coursepic.jpg';
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper, 
    Typography, 
    Box, 
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';

const Membership = () => {
    const [topMargin, setTopMargin] = useState('100px');
    // const [openAlert, setOpenAlert] = useState(true);

    useEffect(() => {
        const updateTopMargin = () => {
            const headerHeight = document.querySelector('.header-container').offsetHeight;
            setTopMargin(`${headerHeight}px`);
        };

        updateTopMargin();
        window.addEventListener('resize', updateTopMargin);

        return () => {
            window.removeEventListener('resize', updateTopMargin);
        };
    }, []);

    // const handleCloseAlert = () => {
    //     setOpenAlert(false);
    // };

    const tableData = [
        {
            type: 'Elementary Member',
            annualPrice: '$1403',
            monthlyInstalment: '$116.92',
            gvaFee: '$99',
            memberAssociation: '$40',
            personalInsurance: '$69'
        },
        {
            type: '7 Day Member',
            annualPrice: '$2163',
            monthlyInstalment: '$180.25',
            gvaFee: '$99',
            memberAssociation: '$40',
            personalInsurance: '$69'
        },
        {
            type: 'Life Style 60 Points',
            annualPrice: '$630',
            monthlyInstalment: '-',
            gvaFee: '$99',
            memberAssociation: '$40',
            personalInsurance: '$69'
        },
        {
            type: 'Lifestyle 110 points',
            annualPrice: '$1024',
            monthlyInstalment: '-',
            gvaFee: '$99',
            memberAssociation: '$40',
            personalInsurance: '$69'
        },
        {
            type: '6 Day Member',
            annualPrice: '$1983',
            monthlyInstalment: '$165.25',
            gvaFee: '$99',
            memberAssociation: '$40',
            personalInsurance: '$69'
        },
        {
            type: '5 Day Member',
            annualPrice: '$1751',
            monthlyInstalment: '$145.92',
            gvaFee: '$99',
            memberAssociation: '$40',
            personalInsurance: '$69'
        },
        {
            type: '2 Day Member',
            annualPrice: '$850',
            monthlyInstalment: '-',
            gvaFee: '$99',
            memberAssociation: '$40',
            personalInsurance: '$69'
        },
        {
            type: 'Intermediate Member (18-28Years)',
            annualPrice: '$840',
            monthlyInstalment: '$70',
            gvaFee: '$99',
            memberAssociation: '$40',
            personalInsurance: '$69'
        },
        {
            type: 'Junior Member (8-17Years)',
            annualPrice: '$315',
            monthlyInstalment: '-',
            gvaFee: '$71',
            memberAssociation: '$40',
            personalInsurance: '$69'
        }
    ];

    const renderTable = (data) => (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>MEMBER TYPES</TableCell>
                        <TableCell>ANNUAL PRICE</TableCell>
                        <TableCell>MONTHLY INSTALMENT</TableCell>
                        <TableCell>COMPULSORY FEE-GVA</TableCell>
                        <TableCell>MEMBER ASSOCIATION</TableCell>
                        <TableCell>PERSONAL INSURANCE</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.type}>
                            <TableCell>{row.type}</TableCell>
                            <TableCell>{row.annualPrice}</TableCell>
                            <TableCell>{row.monthlyInstalment}</TableCell>
                            <TableCell>{row.gvaFee}</TableCell>
                            <TableCell>{row.memberAssociation}</TableCell>
                            <TableCell>{row.personalInsurance}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <div className="membership-page" style={{ marginTop: topMargin }}>
            {/* <Dialog
                open={openAlert}
                onClose={handleCloseAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Important Notice"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please note membership fee will increase after 1st of March 2025
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleCloseAlert} 
                        style={{ backgroundColor: '#006400', color: 'white' }}
                        variant="contained"
                        autoFocus
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog> */}
            <Header />
            <div className="Membership-grid">
                <div className="grid-item elementarysixdaymemberInfo">
                    <h2>Elementary 6-Day Member (New Members Special)</h2>
                    <p>➤Sunday to Friday Playing Rights</p>
                    <p>➤No joining fee</p>
                    <p>➤Full use of our clubhouse facilities</p>
                    <p>➤Handicap and golf link number</p>
                    <p>➤Do not qualify for monthly medal</p>
                    <Box className="elementarysixdaymemberTable">
                        {renderTable([tableData[0]])}
                    </Box>
                    <p>‣Please Note.  All compulsory items due with Annual/First instalment – GVA$99, Personal Insurance $69, Members Association $40. (Members Association—Secondary year fee $20), Administration Fee $55 for setting up payment plan</p>
                    <p>‣Membership Year starts 1 March. Pro Rata rates apply depending on the time of year for application</p>
                    <Link to="https://www.yeringmeadows.com.au/cms/membership/membership-register-form/">
                        <motion.button 
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 1 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                            className="button full-width-button" 
                        >
                            Apply Here
                        </motion.button>
                    </Link>
                </div>
                <div className="grid-item elementarysixdaymemberImg">
                    <img src={sampleimg} alt="elementarysixdaymember" />
                </div>
                <div className="grid-item sevendaymemberImg">
                    <img src={sampleimg} alt="sevendaymember" />
                </div>
                <div className="grid-item sevendaymemberInfo" >
                    <h2>7-day Member</h2>
                    <p>➤Monthly Instalments Available</p>
                    <p>➤Monday to Sunday Playing Rights</p>
                    <p>➤No joining fee</p>
                    <p>➤Reciprocal rights to other golf clubs</p>
                    <p>➤Full use of our clubhouse facilities</p>
                    <p>➤Handicap and golf link number</p>
                    <div className="sevendayTable">
                        <Box>
                            {renderTable([tableData[1]])}
                        </Box>
                    </div>
                    <p>‣Please Note.  All compulsory items due with Annual/First instalment – GVA$99, Personal Insurance $69, Members Association $40. (Members Association—Secondary year fee $20), Administration Fee $55 for setting up payment plan</p>
                    <p>‣Membership Year starts 1 March. Pro Rata rates apply depending on the time of year for application</p>
                    <Link to="https://www.yeringmeadows.com.au/cms/membership/membership-register-form/">
                        <motion.button
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 1 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                            className="button full-width-button" 
                        >
                            Apply Here
                        </motion.button>
                    </Link>
                </div>
                <div className="grid-item lifestylesixtypointmemberInfo">
                    <h2>Lifestyle 60 points</h2>
                    <p>➤Monthly Instalments not available</p>
                    <p>➤Monday to Sunday Playing Rights</p>
                    <p>➤No joining fee</p>
                    <p>➤Full use of our clubhouse facilities</p>
                    <p>➤Full 7-day access to our driving range and practice greens</p>
                    <p>➤Handicap and golf link number</p>
                    <Box className="lifestylesixtypointmemberTable">
                        {renderTable([tableData[2]])}
                    </Box>
                    <p>‣Membership Year starts 1 March. Pro Rata rates apply depending on the time of year for application</p>
                    <Link to="https://www.yeringmeadows.com.au/cms/membership/membership-register-form/">
                        <motion.button 
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 1 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                            className="button full-width-button" 
                        >
                            Apply Here
                        </motion.button>
                    </Link>
                    <br></br>
                    <motion.button
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 1 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                            className="button full-width-button"
                        >
                        <a href="/PDFs/membershippts.pdf" target="_blank" rel="noopener noreferrer" className="button-link">
                            Check here for more Information about Pts
                        </a>
                    </motion.button>
                </div>
                <div className="grid-item lifestylesixtypointmemberImg">
                    <img src={sampleimg} alt="lifestylesixtypointmember" />
                </div>
                <div className="grid-item lifestylehundredtenpointmemberImg">
                    <img src={sampleimg} alt="lifestylehundredtenpointmember" />
                </div>
                <div className="grid-item lifestylehundredtenpointmemberInfo" >
                    <h2>Life Style 110 Points</h2>
                    <p>➤Monthly Instalments Not Available</p>
                    <p>➤Monday to Sunday Playing Rights</p>
                    <p>➤No joining fee</p>
                    <p>➤Full 7-day access to our driving range and practice greens</p>
                    <p>➤Full use of our clubhouse facilities</p>
                    <p>➤Handicap and golf link number</p>
                    <div className="lifestylehundredtenpointmemberTable">
                        <Box>
                            {renderTable([tableData[3]])}
                        </Box>
                    </div>
                    <p>‣Membership Year starts 1 March. Pro Rata rates apply depending on the time of year for application</p>
                    <Link to="https://www.yeringmeadows.com.au/cms/membership/membership-register-form/">
                        <motion.button
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 1 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                            className="button full-width-button" 
                        >
                            Apply Here
                        </motion.button>
                    </Link>
                    <br></br>
                    <motion.button
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 1 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                            className="button full-width-button"
                        >
                        <a href="/PDFs/membershippts.pdf" target="_blank" rel="noopener noreferrer" className="button-link">
                            Check here for more Information about Pts
                        </a>
                    </motion.button>
                </div>
                <div className="grid-item sixdaymemberInfo">
                    <h2>6-Day Member</h2>
                    <p>➤Monthly Instalments Available</p>
                    <p>➤Sunday to Friday Playing Rights</p>
                    <p>➤No joining fee</p>
                    <p>➤Reciprocal rights to other golf clubs</p>
                    <p>➤Full use of our clubhouse facilities</p>
                    <p>➤Handicap and golf link number</p>
                    <div className="sixdayTable">
                        <Box>
                            {renderTable([tableData[4]])}
                        </Box>
                    </div>
                    <p>‣Please Note.  All compulsory items due with Annual/First instalment – GVA$99, Personal Insurance $69, Members Association $40. (Members Association—Secondary year fee $20), Administration Fee $55 for setting up payment plan</p>
                    <p>‣Membership Year starts 1 March. Pro Rata rates apply depending on the time of year for application</p>
                    <Link to="https://www.yeringmeadows.com.au/cms/membership/membership-register-form/">
                        <motion.button 
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 1 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                            className="button full-width-button" 
                        >
                            Apply Here
                        </motion.button>
                    </Link>
                </div>
                <div className="grid-item sixdaymemberImg">
                    <img src={sampleimg} alt="sixdaymember" />
                </div>
                <div className="grid-item fivedaymemberImg">
                    <img src={sampleimg} alt="fivedaymember" />
                </div>
                <div className="grid-item fivedaymemberInfo" >
                    <h2>5-Day Member (Monday to Friday Member access)</h2>
                    <p>➤Monthly Instalments Available</p>
                    <p>➤Monday to Friday Playing Rights</p>
                    <p>➤No joining fee</p>
                    <p>➤Full use of our clubhouse facilities</p>
                    <p>➤Handicap and golf link number</p>
                    <div className="fivedayTable">
                        <Box>
                            {renderTable([tableData[5]])}
                        </Box>
                    </div>
                    <p>‣Please Note.  All compulsory items due with Annual/First instalment – GVA$99, Personal Insurance $69, Members Association $40. (Members Association—Secondary year fee $20), Administration Fee $55 for setting up payment plan</p>
                    <p>‣Membership Year starts 1 March. Pro Rata rates apply depending on the time of year for application</p>
                    <Link to="https://www.yeringmeadows.com.au/cms/membership/membership-register-form/">
                        <motion.button
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 1 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                            className="button full-width-button" 
                        >
                            Apply Here
                        </motion.button>
                    </Link>
                </div>
                <div className="grid-item twodaymemberInfo">
                    <h2>2-day Member (2 Fixed Weekdays Monday,Tuesday,Thursday or Friday)</h2>
                    <p>➤Full payment required when signing up</p>
                    <p>➤Select 2 fixed weekdays (Monday, Tuesday After 9:30am, Thursday or Friday) only playing rights</p>
                    <p>➤No joining fee</p>
                    <p>➤Full use of our clubhouse facilities</p>
                    <p>➤Handicap and golf link number</p>
                    <div className="twodayTable">
                        <Box>
                            {renderTable([tableData[6]])}
                        </Box>
                    </div>
                    <p>‣Membership Year starts 1 March. Pro Rata rates apply depending on the time of year for application.</p>
                    <Link to="https://www.yeringmeadows.com.au/cms/membership/membership-register-form/">
                        <motion.button 
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 1 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                            className="button full-width-button" 
                        >
                            Apply Here
                        </motion.button>
                    </Link>
                </div>
                <div className="grid-item twodaymemberImg">
                    <img src={sampleimg} alt="twodaymember" />
                </div>
                <div className="grid-item intermemberImg">
                    <img src={sampleimg} alt="intermember" />
                </div>
                <div className="grid-item intermemberInfo" >
                    <h2>Intermediate</h2>
                    <p>➤Monthly instalments available</p>
                    <p>➤No joining fee</p>
                    <p>➤Reciprocal rights to other golf clubs</p>
                    <p>➤Full use of our clubhouse facilities</p>
                    <p>➤Full 7-day access to our driving range and practice green</p>
                    <p>➤Handicap and golf link number</p>
                    <div className="interTable">
                        <Box>
                            {renderTable([tableData[7]])}
                        </Box>
                    </div>
                    <p>‣Please Note.  All compulsory items due with Annual/First instalment – GVA$99, Personal Insurance $69, Members Association $40. (Members Association—Secondary year fee $20), Administration Fee $55 for setting up payment plan</p>
                    <p>‣Membership Year starts 1 March. Pro Rata rates apply depending on the time of year for application</p>
                    <Link to="https://www.yeringmeadows.com.au/cms/membership/membership-register-form/">
                        <motion.button
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 1 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                            className="button full-width-button" 
                        >
                            Apply Here
                        </motion.button>
                    </Link>
                </div>
                <div className="grid-item juniorInfo">
                    <h2>Junior</h2>
                    <p>➤Full payment required when signing up</p>
                    <p>➤No joining fee</p>
                    <p>➤Reciprocal rights to other golf clubs</p>
                    <p>➤Full use of our clubhouse facilities</p>
                    <p>➤Full 7-day access to our driving range and practice greens</p>
                    <p>➤Handicap and golf link number</p>
                    <div className="juniorTable">
                        <Box>
                            {renderTable([tableData[8]])}
                        </Box>
                    </div>
                    <p>‣Membership Year starts 1 March. Pro Rata rates apply depending on the time of year for application</p>
                    <Link to="https://www.yeringmeadows.com.au/cms/membership/membership-register-form/">
                        <motion.button 
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 1 }}
                            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                            className="button full-width-button" 
                        >
                            Apply Here
                        </motion.button>
                    </Link>
                </div>
                <div className="grid-item juniorImg">
                    <img src={sampleimg} alt="juniormember" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Membership;
