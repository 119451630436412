import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import './HomePage.css';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import image1 from '../assets/images/content/infopic1.jpg';
import image4 from '../assets/images/content/practicefacilities.jpg';
import image3 from '../assets/images/content/infopic3.jpg';
import image2 from '../assets/images/content/infopic4.jpg';
import News1 from '../assets/images/latestnews/Bunker.png';
import News2 from '../assets/images/latestnews/news2.jpg';
import News3 from '../assets/images/latestnews/TGS.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GoogleMapComponent from '../components/GoogleMapComponent';
import { motion } from 'framer-motion';
import Link from '@mui/material/Link';
import ParallaxBanner from '../components/Banner';

export default function HomePage() {
    // useEffect(() => {
    //     Swal.fire({
    //         title: 'Important Notice 23/02/25',
    //         html: `
    //             <p style="color: #2E2E2E; margin-bottom: 1rem; font-family: 'AP Pro Medium';">
    //                 Due to power outages, we are unable to take phone bookings at this time.
    //             </p>
    //             <p style="color: #2E2E2E; margin-bottom: 1rem; font-family: 'AP Pro Medium';">
    //                 Please email us at 
    //                 <a href="mailto:ops@yeringmeadows.com.au" style="color: #2E2E2E; text-decoration: underline;">
    //                     ops@yeringmeadows.com.au
    //                 </a>
    //                 , and we will respond as soon as possible.
    //             </p>
    //             <p style="color: #2E2E2E; font-family: 'AP Pro Medium';">
    //                 Thank you for your understanding.
    //             </p>
    //         `,
    //         background: '#F3E8DD',
    //         confirmButtonText: 'Understood',
    //         confirmButtonColor: '#2E2E2E',
    //         width: '32em',
    //         titleColor: '#2E2E2E',
    //         customClass: {
    //             title: 'swal-title',
    //             popup: 'swal-popup'
    //         }
    //     });

    //     // Add custom styles
    //     const style = document.createElement('style');
    //     style.textContent = `
    //         .swal-popup {
    //             border-radius: 5px;
    //         }
    //         .swal-title {
    //             color: #2E2E2E !important;
    //             font-family: 'GTSD Bold' !important;
    //             font-size: 32px !important;
    //         }
    //         .swal2-confirm {
    //             background-color: #FFFBB3 !important;
    //             color: #2E2E2E !important;
    //             font-family: 'GTSD Bold' !important;
    //             transition: background-color 0.3s ease, opacity 0.3s ease !important;
    //         }
    //         .swal2-confirm:hover {
    //             background-color: #F3E8DD !important;
    //             opacity: 0.5 !important;
    //         }
    //     `;
    //     document.head.appendChild(style);
    // }, []);
    
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    return (
        <div>
            <Header />
            <div className='Content-container'>

                <div className='homevideo'>
                    <video autoPlay loop muted preload="metadata">
                        <source src="/videos/video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <div className="welcome-word">
                        <div className='welcoming-title'>Welcome To</div>
                        <div className='hometitle'>Yering Meadows Golf Club</div>
                    </div>
                    <div className="booking-container">
                        <div className='book-tee-time'>
                            <a href="https://www.yeringmeadows.com.au/guests/bookings/ViewPublicCalendar.msp?booking_resource_id=3000000" className="book-tee-time-button">Book a Tee Time</a>
                        </div>
                        {/* <div className='book-driving-range'>
                            <a href="https://www.yeringmeadows.com.au/guests/bookings/ViewPublicCalendar.msp?booking_resource_id=3000000" className="book-driving-range-button">Book Driving Range</a>
                        </div> */}
                    </div>
                    <div className='explore-arrow'>
                        <div><a href="#golf-club-target" style={{textDecoration: 'none', color: 'inherit'}}>Explore</a></div>
                        <motion.div
                        initial={{ y: 0 }} 
                        whileHover={{ y: 10 }}
                        transition={{ 
                            duration: 0.3, 
                            yoyo: Infinity, 
                            ease: "easeInOut" 
                        }}
                        >
                        <ExpandMoreIcon />
                        </motion.div>
                    </div>

                </div>

                <div style={{height: '70px', visibility: 'hidden'}} id="golf-club-target"></div>

                <div className='information-section' id="golf-club-section">
                    <h2>The Golf Club</h2>
                    <p>
                        The Yering Meadows Golf Club, formerly known as the Croydon Golf Club, was established in 1925 and has earned a reputation as the premier golf club in the eastern suburbs of Melbourne. Now located on 330 acres in the Yarra Valley, the world-class golf course, designed by architect Ross Watson and reviewed by reowned golf course architect Mike Clayon, is arguably the finest in the area. Yering Meadows is known for its friendly atmosphere, and the clubhouse facilities provide an ideal environment for relaxation and enjoyment for members, guests, and the public.
                    </p>
                </div>

                <div className="photo-gallery">
                    <a href="/golf">
                        <div className="HPimage-container">
                            <div className="image-text">Golf</div>
                            <img src={image1} alt="description-1" />
                        </div>
                    </a>
                    <a href="/membership">
                        <div className="HPimage-container">
                            <div className="image-text">Membership</div>
                            <img src={image2} alt="description-2" />
                        </div>
                    </a>
                    <a href="/functions-events">
                        <div className="HPimage-container">
                            <div className="image-text">Function</div>
                            <img src={image3} alt="description-3" />
                        </div>
                    </a>
                    <a href="/practice-facilities">
                        <div className="HPimage-container">
                            <div className="image-text">Practice Facilities</div>
                            <img src={image4} alt="description-4" />
                        </div>
                    </a>
                </div>

            
                <div className="latest-news-section">
                    <h2>Latest News</h2>
                    <div className="whatsNewContainer">
                        <div className="whatsNewSection">
                            <h3>Bunker Works</h3>
                            <Link href="/PDFs/YeringMeadowsBunkerWorks.pdf" target="_blank" rel="noopener">
                                <img src={News1} alt="News1" />
                            </Link>
                        </div>

                        <div className="whatsNewSection">
                            <h3>It's Time To Return</h3>
                            <Link href="/PDFs/Tabletopads.pdf">
                                <img src={News2} alt="News2" />
                            </Link>
                        </div>

                        <div className="whatsNewSection">
                            <h3>Twilight Golf Special</h3>
                            <Link href="/PDFs/YMGCCompPoster.pdf">
                                <img src={News3} alt="News3" />
                            </Link>
                        </div>
                    </div>
                </div>

                <ParallaxBanner/>

                <div className="how-to-find-us-section">
                    <h2>How to Find Us</h2>
                    <p>Nestled within the panoramic embrace of Yering's rolling landscapes, just a stone's throw from Melbourne's bustling heart, Yering Meadows Golf Club awaits your arrival. Discover the gateway to an enchanting experience at 178 – 180 Victoria Road, Yering, VIC 3770, where every visit feels like a serene retreat from the everyday.</p>
                    <GoogleMapComponent apiKey={apiKey} />
                </div>
                
            </div>

            <Footer />
        </div>
    );
}
